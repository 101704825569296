import React from 'react';
import CategoryBox from './categorybox.js';
import CountdownBox from './countdownbox.js';
import ProduceSection from './audiovideo/produce_section.jpg';
import StreetSymphony from './audiovideo/street_symphony.jpg';


export default function Banner({ playAudio }) {
  return (
    <div>
      <section id="main-image">
        <a href="#mobilecategories" id="explorebutton" onClick={playAudio} >
          <h2>Explore</h2>
        </a>
        <div id="categorygroup">
          <CategoryBox categorypic={ProduceSection} alt="The Studio" categorytitle = "The Produce Section" categoryblurb = "This show is dedicated to producers and musicians with inside interviews on materials and equipment they used in their production. This production will be hand down to Street Symphony." />
          <CountdownBox categoryid="countdown" categorytitle="These Three Flavors will be available on November 15th"  />
          <CategoryBox categorypic={StreetSymphony} alt="The Studio" categorytitle = "Street Symphony" categoryblurb = "The Production will not be without void; it is Street Symphony's mission to reach out wherever it's necessary - to see Who's Hungry Enough. With that being said, these three flavors are going to be available to you at the rending of the countdown. Enjoy, and we'll see you soon!" />
        </div>
      </section>
    </div>
  )
}