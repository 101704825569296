import React, {useState} from 'react'
import Logo from './audiovideo/IMG_6550.JPG';

export default function Nav() {

  const [burger, setBurger] = useState(false);
  let ToggleClass = () => {
    setBurger(!burger); 
  };
  
  return (
    <nav>
        <ul>
          <li className="logo">
           
              <img src={Logo}></img>
          
          </li>
          <li className={burger? "hamburger cross" : "hamburger"} onClick={ToggleClass}><a href="#">
            <span id="firstbar"></span>
            <span id="secondbar"></span>
            <span id="thirdbar"></span>
          </a></li>
          <div className={burger? "dropdown" : "hidden"}>
          <li className="links"><a href="#">STORE</a></li>
          <li className="links"><a href="#">CONTACT</a></li>
          <li className="links"><a href="#">ABOUT</a></li>
          <li className="links"><a href="#">NEWS</a></li>
          <li className="links"><a href="#">HOME</a></li>
          </div>
        </ul>
    </nav>
  )
}