import './App.css';
import Nav from './nav.js';
import Banner from './banner.js';
import Home from './pages/Home.js';
import ThreeFlavors from './audiovideo/3Flavorz.mp3';
import Overlay from './overlay.js';
import Footer from './footer.js';
import { useRef } from 'react';

function App() {
  const audioRef = useRef(null);

  function playAudio() {
    audioRef.current.play();
  }

  return (
    <div className="body">
      <Overlay />
      <audio loop autoPlay src={ThreeFlavors} id="audio" ref={audioRef}></audio>
      <Nav />
      <Banner playAudio={playAudio}/>
      <Home />
      <Footer />
    </div>
  );
}

export default App;
