import React from 'react';

export default function CategoryBox(props) {
  return (
    <a className="category" id={props.categoryid}>
      <img src={props.categorypic} alt={props.categoryalt} />
      <div className="categorydescription">
        <h3>{props.categorytitle}</h3>
        <p>{props.categoryblurb}</p>
      </div>
    </a>
  )
}