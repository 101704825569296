import React, {useState, useEffect} from 'react';

export default function CountdownBox(props) {

  useEffect(() => {
    let checker
    const release = new Date(2023, 10, 15, 0, 0, 0, 0);
    checker = setInterval((function checkTime() {
      let now = new Date();
      let time_until = release - now;
      let days = Math.floor(time_until / (1000 * 60 * 60 * 24));
      let dayString = String(days).padStart(2, '0');
      let hours = Math.floor((time_until % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let hourString = String(hours).padStart(2, '0');
      let minutes = Math.floor((time_until % (1000 * 60 * 60)) / (1000 * 60));
      let minuteString = String(minutes).padStart(2, '0');
      let seconds = Math.floor((time_until % (1000 * 60)) / 1000);
      let secondString = String(seconds).padStart(2, '0');
      if (time_until <= 0) {

        let clockElements = document.getElementsByClassName("countdownclock");
        let downloadElements = document.getElementsByClassName("download");
        let countdownHeaders = document.getElementsByClassName("category countdown");
        
        for (let clock of clockElements) clock.innerHTML = "";
        for (let download of downloadElements) {
          download.disabled = false;
          download.onclick = (event) => {
            let link = document.createElement('a');
            link.href="/audiovideo/3Flavorz.mp3"
            link.download = "3Flavorz.mp3";
            console.log(link.href)
            link.click();
          }
        }

        for (let header of countdownHeaders) {
          let h3 = header.getElementsByTagName("h3")[0];

          h3.innerHTML = "Download NOW! Enjoy."
          
        }

        clearInterval(checker);
      } else {
        let countdownElements = document.getElementsByClassName("countdownclock");
        for (const element of countdownElements) {
          element.innerHTML = dayString + ":" + hourString + ":" + minuteString + ":" + secondString;
        }
      }
      return checkTime;
    }) (), 1000);
  }, [])
  
  return (
    <a className="category countdown" >
      <div className="countdownarea">
        <p className="countdownclock">These 3 Flavors will be Available on November 15th</p>
      </div>
      <div className="categorydescription">
        <h3>{props.categorytitle}</h3>
        <button type="button" className="download" disabled>Download!</button>
      </div>
    </a>
  )
}