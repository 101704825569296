import React from 'react';
import CategoryBox from '../categorybox.js';
import CountdownBox from '../countdownbox.js';
import ProduceSection from '../audiovideo/produce_section.jpg';
import StreetSymphony from '../audiovideo/street_symphony.jpg';
import Showcasting from '../audiovideo/showcasting.jpg';

export default function Home() {
    return(
        <div id="homesection">
            <div id="mobilecategories">
                <CategoryBox categoryid="" categorypic={ProduceSection} alt="The Studio" categorytitle = "The Produce Section" categoryblurb = "This show is dedicated to producers and musicians with inside interviews on materials and equipment they used in their production. This production will be hand down to Street Symphony." />
                <CountdownBox categoryid="countdown" categorytitle="These Three Flavors will be available on November 15th" />
                <CategoryBox categoryid="" categorypic={StreetSymphony} alt="The Studio" categorytitle = "Street Symphony" categoryblurb = "The production will not be without void; it is Street Symphony's mission to reach out wherever it's necessary - to see who's hungry enough to be fed. With that being said, these three flavors are going to be available to you at the rending of the countdown. Enjoy, and we'll see you soon! ~ Street Symphony" />
            </div>
            <section id="details">
                <h2>Details</h2>
                <div className="details_info">
                    <div className="details_left">
                        <h3>Artists, Producers, and Editors</h3>
                        <p><span className="bolded">Artists</span> - Must bring two records/songs: one you will perform live; the other is for your files.</p>
                        <p><span className="bolded">Producers</span> - Must bring 3 different flavors as you can show off your skills. All production MUST have you tag or name; if not, we will NOT accept.</p> 
                        <p><span className="bolded">Editors</span> - Must bring 3 videos showcasing your skills via music video/promotional content, etc or any content you have done in the past.</p> 
                    </div>
                    <div className="details_right">
                    <h3>DJ's and Actors</h3>
                        <p><span className="bolded">DJ's</span> - If you are a battle DJ, you must bring us a video footage of your set as well as a 20 minute mix. Make sure your tags and name are on the video. Auditions will be set at a later date at our studio. By providing this information, it will guarantee your spot for casting. </p>
                        <p><span className="bolded">Actors</span> - Must bring a 2 to a 3 minute video interview or a report of any content. They must give your contact information. Auditions will be set at a later date at our studio. By providing this information, it will guarantee your spot for casting.  </p>
                    </div>
                </div>
            </section>
            <section id="directions">
                <div className="image_direct">
                    <h2>Directions</h2>
                    <div className="show_image">
                        <img src={Showcasting}></img>
                    </div>
                    <div className="direction_info">
                        <h3><u><a href="https://maps.app.goo.gl/ARchRSwBEQPQzraS9" target="_blank">Address:</a></u> 88 S Courtland St. East Stroudsburg, PA 18301</h3>
                        <h3>On the 14th of November, auditions start from 3-9pm.</h3>
                    </div>
                </div>
            </section>
            <section id="end_note">
                <h3><i>Hope to see you guys there and looking forward to see everyone’s God-given talent. </i></h3>
            </section>
        </div>
    )
}