import React, {useState} from 'react';

export default function Overlay() {
  const [overlay, setOverlay] = useState(false);
  let offOverlay = () => {
    setOverlay(!overlay);
  }
  
  return (
    <>
      <div id="overlay" className={overlay ? "hideoverlay" : "showoverlay"}>
      <div className="warning">
        <h5>Can't hear anything? Enable autoplay on your browser! </h5>
        <button className="close-button" aria-label="close alert" type="button" onClick={offOverlay}>
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
      </div>
    
    </>
  )
}